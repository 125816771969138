import React, { Fragment, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setLoader] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message);
      return;
    }

    setLoader(true);

    try {
      const payload = {
        amount: props.formData.amount * 100,
      };

      if (props.formData.organization) {
        payload["organization"] = props.formData.organization;
      }

      if (props.formData.event) {
        payload["event"] = props.formData.event;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/donations/payment-intent`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.formData.jwtToken}`,
          },
          body: JSON.stringify(payload),
        }
      );

      const { paymentIntent } = await response.json();

      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret: paymentIntent,
        confirmParams: {
          return_url: `http://donations.hiroek.io/success?amount=${props.formData.amount*100}`,
        },
      });

      setLoader(false);

      if (error) {
        setErrorMessage(error.message);
      } else {
      }
    } catch (err) {
      setLoader(false);
    }
  };

  return (
    <form className="flex flex-col justify-between min-h-screen" onSubmit={handleSubmit}>
      <div className="order-last m-6">
          <Fragment>
            <PaymentElement />

            <button
              className="px-10 py-3 my-3 mx-auto rounded-lg w-full bg-purple-700 font-medium text-sm text-white"
              type="submit"
              disabled={!stripe || !elements || isLoading}
            >
              {isLoading ? "Processing..." : "Finalise"}
            </button>

            {errorMessage && <div>{errorMessage}</div>}
          </Fragment>
      </div>
    </form>
  );
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

const Payment = (props) => {
  const amount = props.payload.amount;
  const fee = 50; // 0.5 gbp
  const percentageToAdd = (amount * 10) / 100;
  const amountInPounds = amount * 100;
  const newAmount = amountInPounds + percentageToAdd * 100 + fee;

  const options = {
    mode: "payment",
    payment_method_types: ["card"],
    currency: "gbp",
    amount: parseInt(newAmount),

    appearance: {},
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm
        formData={props.payload}
        finalAmount={parseInt(newAmount) / 100}
        paymentFee={percentageToAdd}
      />
    </Elements>
  );
};

export default Payment;
