import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Page from "./Success";
import CancelPage from "./Cancel";
import DonationPage from "./donations";
import DonationSetupPage from "./donations-setup";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "success",
    element: <Page />,
  },  {
    path: "cancel",
    element: <CancelPage />,
  },
  {
    path: "donations",
    element: <DonationPage />,
  },
  {
    path: "donation-intent",
    element: <DonationSetupPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
