import errorImg from "./assets/badge-cancel.png";

export default function CancelPaymentPage() {
  return (
    <div className="h-[100vh] w-full flex justify-center items-center flex-col space-y-5 px-5">
      <img src={errorImg} alt="Success" className="w-35 h-35" />
      <p className="text-center font-medium">
        Donation Cancelled
      </p>
    </div>
  );
}
