import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Payment from "./Payment";

export default function DonationPage() {
  const [searchParams] = useSearchParams();

  const [jwtToken, setJwtToken] = useState(null);
  const [amount, setAmount] = useState(0);
  const [event, setEvent] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [benefactor, setBenefactor] = useState(null);

  useEffect(() => {
    setAmount(searchParams.get("amount"));
    setJwtToken(searchParams.get("jwtToken"));
    setEvent(searchParams.get("event"));
    setOrganization(searchParams.get("organization"));
    setBenefactor(searchParams.get("benefactor"));
  }, []);

  if (!jwtToken || amount == 0 || !benefactor || (!event && !organization)) {
    return <></>;
  }

  return (
        <Payment
          payload={{
            event,
            jwtToken,
            organization,
            amount: amount,
            benefactor,
          }}
        />
    
  );
}
