import React, { Fragment, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setLoader] = useState(false);

  const takeSystemCharge = (amount) => {
    const chargePercentage = 0.1; // 10% = 10/100

    let newAmount = Number(amount) - 50;
    newAmount = newAmount / (1 + chargePercentage);

    return newAmount;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    
    if (elements == null) {
      return;
    }
    
    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message);
      return;
    }
    
    setLoader(true);

    try {
      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret: props.payload.clientSecret,
        confirmParams: {
          return_url: `http://donations.hiroek.io/success?amount=${takeSystemCharge(props.payload.amount)}`,
        },
      });

      setLoader(false);

      if (error) {
        setErrorMessage(error.message);
      } else {
      }
    } catch (err) {
      console.log("Error: ", err);
      setLoader(false);
    }
  };

  return (
    <form className="min-h-screen" onSubmit={handleSubmit}>
      <div className="text-center mt-24 mb-16">
        <h3 className="text-lg text-slate-500">
        Please enter your card details and press Finalise to complete your donation to {props.payload.organizations}
        </h3>
      </div>
      <div className="order-last m-6">
        <Fragment>
          <label>Enter your card details</label>
          <PaymentElement />

          <button
            className="px-10 py-3 my-3 mx-auto rounded-lg w-full bg-purple-700 font-medium text-sm text-white"
            type="submit"
            disabled={!stripe || !elements || isLoading}
          >
            {isLoading ? "Processing..." : "Finalise"}
          </button>

          {errorMessage && <div>{errorMessage}</div>}
        </Fragment>
      </div>
    </form>
  );
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

const Subscription = (props) => {
  const options = {
    mode: "subscription",
    amount: parseInt(props.payload.amount),
    currency: "gbp",
    appearance: {},
    
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm payload={props.payload} />
    </Elements>
  );
};

export default Subscription;
