import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Subscription from "./Subscription";

export default function DonationPage() {
  const [searchParams] = useSearchParams();

  const [jwtToken, setJwtToken] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [amount, setAmount] = useState(0);
  const [organizations, setOrganizations] = useState(null);

  useEffect(() => {
    setAmount(searchParams.get("amount"));
    setJwtToken(searchParams.get("jwtToken"));
    setSubscriptionId(searchParams.get("subscriptionId"));
    setClientSecret(searchParams.get("client_secret"));
    setOrganizations(searchParams.get("organizations"));
  }, []);

  if (
    !jwtToken ||
    !subscriptionId ||
    !clientSecret ||
    amount == 0 ||
    !organizations
  ) {
    return <></>;
  }

  return (
    <Subscription
      payload={{
        jwtToken,
        subscriptionId,
        clientSecret,
        organizations,
        amount: amount,
      }}
    />
  );
}
